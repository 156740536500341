@import 'app';

.td {
    padding: 0 !important;
}

.pointer {
    cursor: pointer;
}

.newAssignmentForm {
    label {
        margin-bottom: 0 !important;
    }
}

.scheduleTrigram {
    cursor: pointer;
    margin-right: 5px;
    font-weight: bold;
    white-space: normal;
    margin-bottom: 5px;
    &.is-updated {
        background: #ffdd57;
        font-style: italic;
    }
    &.qualifiedTechnician {
        border: 1px #000000 solid;
    }
    &.inTrainingTechnician {
        border: 1px rgba(189, 33, 48, 1) dashed;
        font-weight: normal;
    }
    &.inQualificationTechnician {
        border: 1px #000000 dashed;
    }
    &.canDrop {
        border: 2px solid #00bb00;
    }
    &.inactiveOperator {
        background: rgba(189, 33, 48, 0.5);
        border-color: rgba(189, 33, 48, 1);
        color: white
    }
    &.notSamePlanningTechnician {
        color: white;
        background: rgba(255, 12, 12, 0.8);
    }
    &.inactiveTechnician {
        text-decoration: line-through;
    }
}

#action-panel {
    min-width: 350px;

    .panel-content {
        max-height: 0;
        overflow: hidden;
        transition: max-height ease-in-out 500ms;
        &.visible {
            max-height: 15rem;
        }
        .sidePanel-action-switch {
            width: 100%;
        }
    }
}

#planningMenuChange {
    position: relative;
    padding: 10px;
    background: #ffe08a;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .title {
        height: 100%;
        display: flex;
        gap: 10px;
        align-items: center;
        font-size: 1.1em;
        font-weight: bold;
        cursor: pointer;
        margin-bottom: 0;
    }
    &.openFull {
        border-radius: 0;
    }
    .content {
        position: absolute;
        overflow: hidden;
        top: 4rem;
        left: -100%;
        background-color: white;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        max-height: 0;
        transition: all .5s ease-in-out;

        .svg {
            color: #000;
        }

        &.openFull {
            max-height: 14rem;
        }
        .container {
            padding: 20px;
        }
        .changeObject {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            margin-bottom: 1rem;

            .objectType {
                font-weight: bold;
            }

            .changeTypes {
                display: flex;
                gap: 5px;
                align-items: center;
                .tags {
                    margin-bottom: 0;
                    flex-wrap: nowrap;
                    .tag {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .save {
            margin-top: 1.5rem;
            text-align: right;
            font-weight: bold;
        }
    }
}

#assignmentModalBody, #deleteDayModalBody {
    padding: 0 !important;
    .modal__content {
        .technician__form__part {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            gap: 1rem;
        }
        padding: 0.5rem;
        .qualifications_list {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .card-footer {
        border-top: none !important;
        .card-footer-item {
            cursor: pointer;
            font-weight: bold;
            border-top: 1px solid #000;
            &.buttonDisabled {
                cursor: default;
                font-weight: normal;
                background-color: #f8f8f8;
                border-top: 1px solid #dbdbdb;
                font-style: italic;
            }
        }
    }
}

#assignedScheduleModalBody {
    padding: 0 !important;
    .technicianCapacity {
        font-weight: normal !important;
    }
    .assignedScheduleFormTechnicians {
        .tag{
            cursor: pointer;
        }
    }
    .level-item {
        flex-shrink: 1;
    }
    .card-footer {
        border-top: none !important;
        .card-footer-item {
            cursor: pointer;
            font-weight: bold;
            border-top: 1px solid #000;
            &.buttonDisabled {
                cursor: default;
                font-weight: normal;
                background-color: #f8f8f8;
                border-top: 1px solid #dbdbdb;
                font-style: italic;
            }
        }
    }
}

#planningTable {
    border: none;
    margin: 10px;
    table-layout:fixed;
    border-collapse: separate;
    height: 100%;

    .visualizationSwitcher {
        cursor: pointer;
    }

    thead {
        background: inherit;
        border: none;
        box-shadow: none !important;

        &.operatorDaysHead {
            &:after {
                content: '';
                height: 10px;
                display: block;
            }
        }

        th {
            padding-bottom: 0.25rem;
            padding-top: 0.25rem;
            border: white 1px solid;
            text-align: center;
        }

        .fullHeader {
            padding-bottom: 0.5rem !important;
            padding-top: 0.5rem !important;

            //fix for Bulma
            .columns {
                display: flex !important;
                align-items: center;
                justify-content: space-between;
            }

            .incompleteSolutionTableHeader {
                font-style: italic;
            }
        }

        .headerTitle {
            text-align: center;
            font-weight: bold;
            font-size: 1.5em;
        }

        .navigationBackward {
            text-align: left;
            padding-left: 1.5rem;
            cursor: pointer;
        }

        .navigationForward {
            text-align: right;
            padding-right: 1.5rem;
            cursor: pointer;
        }

        .dataHeader {
            vertical-align: middle;
            font-weight: bold;
            font-size: 1.1em;
            padding: .5rem 5px;
            border-bottom: none;
            border-top: none;
            box-shadow: 0 2px 15px -2px rgba(0,0,0,0.15);
            &.print {
                box-shadow: none;
            }
        }

        .cornerHeader {
            vertical-align: middle;
            border-bottom: none;
            border-top: none;
            border-left: white 10px solid;
        }
    }

    tbody {
        margin-top: 1rem;
        tr {
            border: none !important;
            height: 100%;
            td {
                height: 100%;
                border: white 1px solid;
                &.indexCell {
                    text-align:  center;
                    vertical-align: middle;
                    font-weight: bold;
                    background: #eee;
                    border-left: 10px #bbb solid;
                    border-bottom-left-radius: 10px;
                    border-top-left-radius: 10px;
                    &.inactiveOperator {
                        background-color: rgba(189, 33, 48, 0.5);
                        border-left: 10px rgba(189, 33, 48, 1) solid;
                        color: white
                    }
                    .indexCellContainer {
                        padding: 10px;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .indexCellContent {
                            font-weight: bold;
                            cursor: pointer;
                            text-align: center;
                        }
                    }
                }
                .planningCellOperator {
                    height: 100%;
                    position: relative;
                    &.canDrop {
                        border: 2px solid #189d37;
                    }
                    .backdrop {
                        position: absolute;
                        display: none;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        z-index:1000000;
                        &.noDrop {
                            display: block;
                            background-color: rgba(255,255,255,0.5);
                        }
                    }
                }
                .PlanningCellContent {
                    position: relative;
                    height: 100%;
                    min-height: 4rem;
                    background-attachment: fixed;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .scheduleContent {
                        padding: 10px 10px 1.3rem 10px;
                        .cellScheduleTitle {
                            cursor: pointer;
                            font-weight: bold;
                            margin-bottom: 5px;
                            border-bottom: white 1px solid;
                            &.is-updated {
                                border-left: 5px solid #ffdd57;
                                padding-left: 5px;
                                font-style: italic;
                            }
                        }

                        .cellScheduleContent {
                            white-space: normal;

                            .newTrigramButton {
                                opacity: 80%;
                            }
                        }
                    }
                    .dayCellContent {
                        padding: 5px;
                        display: flex;
                        flex-direction: column;
                        gap: 2px;
                        align-items: center;
                        justify-content: center;
                        .scheduleName {
                            font-weight: bold;
                        }
                        .lineName {

                        }
                        .dayOff {
                            font-weight: bold;
                        }
                        &.dayCellReplacement {
                            cursor: pointer;
                            flex-direction: row;
                            gap: 5px
                        }
                    }
                    .newScheduleContent {
                        position: absolute;
                        bottom: 5px;
                        left: 0;
                        right: 0;
                        min-height: 1.1rem;
                        display: flex;
                        justify-content: center;

                        svg {
                            cursor: pointer;
                        }
                    }
                    &.assigned {
                        cursor: pointer;
                    }
                    &.inactiveDay {
                        background: -webkit-repeating-linear-gradient(45deg, #fff,#fff 5px,#ccc 5px,#ccc 10px);
                        background: repeating-linear-gradient(45deg, #fff,#fff 5px,#ccc 5px,#ccc 10px);

                        &.print {
                            background: #eee;
                        }
                    }
                    &.noAssignmentsDay {
                       background: -webkit-repeating-linear-gradient(45deg, #606dbc,#606dbc 5px,#fff 5px,#fff 10px); 
                       background: repeating-linear-gradient(45deg, #606dbc,#606dbc 5px,#fff 5px,#fff 10px);

                        &.print {
                            background: #eee;
                        }
                    }
                    &.notSamePlanningMode {
                        border: 1px solid rgba(255, 12, 12, 0.8);
                    }

                }
            }
        }
    }
}

#commentsPanel {
    margin-top: 10px;
    padding: 0.75rem;
    .panel{
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        .panel-heading{
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
    }
}

#planningTableLegend {
    display: flex;
    gap: 20px;
    margin: 20px 10px;
    .legend-list {
        li {
            margin-bottom: 3px
        }
    }
}
