@import '~bulma/bulma';
@import "~bulma-helpers/css/bulma-helpers.min.css";

html {
    font-size: 13px;
    overflow: scroll;
}

html, body{
    //min-height: 100vh;
}

#app-content {
    position: relative;
    padding: 0;
    margin: 1.25rem 0 0;
}